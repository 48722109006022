// $(document).ready(function(){
//   $(".owl-carousel").owlCarousel({});
// });

 AOS.init();
AOS.refresh();

// slide parceiros
$(document).ready(function(){
  $("#parceiros-slide").owlCarousel({
    items: 1,
    margin: 48,
    loop: true,
    autoWidth: false,
    slideTransition: 'linear',
    autoplay: true,
    autoplayHoverPause: false,
    autoplayTimeout: 1500,
    // autoplaySpeed: 4000,
    nav: false,
    navText: ["<img src='user/themes/hasm/images/icons/prev-2.png'>", "<img src='user/themes/hasm/images/icons/next-2.png'>"],
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 5
      },
      1000: {
        items: 6
      }
    }
  });
});

// slide bercario
$(document).ready(function(){
  $("#bercario-carousel").owlCarousel({
    items: 1,
    loop: true,
    margin: 16,
    autoWidth: true,
    // slideTransition: 'linear',
    autoplay: true,
    autoplayTimeout: 1500,
    autoplayHoverPause: true,
    // autoplaySpeed: 4000,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 5
      },
      1000: {
        items: 10
      }
    }
  });
});

// banner da home
$(document).ready(function(){
  $("#banner-carousel").owlCarousel({
  	items: 1,
  	nav: true,
    navText: ["<img src='user/themes/hasm/images/icons/banner/prev.svg'>", "<img src='user/themes/hasm/images/icons/banner/next.svg'>"],
  	dots: false,
    autoplay: true,
  });
});

// slide estrutura
$(document).ready(function(){
  $("#estrutura-carousel").owlCarousel({
    items: 1,
    margin: 40,
    autoplay: true,
    nav: true,
    navText: ["<img src='user/themes/hasm/images/icons/prev-2.png'>", "<img src='user/themes/hasm/images/icons/next-2.png'>"],
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  });
});

// fotos da página serviços
$(document).ready(function(){
  $("#carousel-servicos").owlCarousel({
    items: 1,
    margin: 0,
    nav: true,
    navText: ["<img src='user/themes/hasm/images/icons/prev-2.png'>", "<img src='user/themes/hasm/images/icons/next-2.png'>"],
    dots: false,
    autoWidth: false,
  });
});

// carousel com os medicos
$(document).ready(function(){
  $("#carousel-2").owlCarousel({
  	items: 4,
  	nav: true,
    margin: 60,
    navText: ["<img src='user/themes/hasm/images/icons/prev.svg'>", "<img src='user/themes/hasm/images/icons/next.svg'>"],
  	dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
  	responsive: {
  		0: {
  			items: 1
  		},
  		600: {
  			items: 3
  		},
  		1000: {
  			items: 4
  		}
  	}
  });
});

// script pra efeito nos numeros da home
$(document).ready(function(){
  $('.count').each(function () {
    $(this).prop('Counter',0).animate({
      Counter: $(this).text()
    }, {
      duration: 4000,
      easing: 'swing',
      step: function (now) {
        $(this).text(Math.ceil(now));
      }
    });
  });
})


$("document").ready(function($){
    num = $('.navbar').offset().top;
    var nav = $('.navbar');

    $(window).scroll(function () {
        if ($(this).scrollTop() >= num) {
            nav.addClass("fixed-nav");
        } else {
            nav.removeClass("fixed-nav");
        }
    });
});


// esconde/mostra a barra verde do topo
$('.toggle-topbar').click(function (e) {
  $(this).addClass('-active');
  $('.topbar-nav').toggleClass('-active');
  e.preventDefault();
});

/// menu
$('.toggle-nav').click(function (e) {
  $(this).addClass('-active');
  $('.main-nav').toggleClass('-active');
  e.preventDefault();
});

/// sub-menu
if ($(window).width() <= 920) {
  $('.has-children > a').click(function (e) {
    $('.has-children > ul').toggleClass('-active')
    e.preventDefault()
  })
} else {
  $('.has-children').hover(function () {
    $('.has-children > ul').toggleClass('-active')
  })
}